import { useEffect, useRef } from 'react'
import { useInView } from 'framer-motion'
import type { LottieRefCurrentProps } from 'lottie-react'
import Lottie from 'lottie-react'

import * as IntroQuadrantAnimation from '@assets/lottie/intro.json'

export const IntroAnimation = () => {
  const ref = useRef(null)
  const lottieRef = useRef<LottieRefCurrentProps>(null)
  const isInView = useInView(ref, { once: true, amount: 0.7 })

  useEffect(() => {
    if (lottieRef && lottieRef.current && isInView) {
      lottieRef.current.setSpeed(1.5)
      lottieRef.current.play()
    }
  }, [isInView, lottieRef])

  return (
    <div
      ref={ref}
      className={
        '2xl:max-h-auto pointer-events-none flex max-h-[80vw] select-none justify-center'
      }
    >
      <Lottie
        animationData={IntroQuadrantAnimation}
        lottieRef={lottieRef}
        autoplay={false}
        loop={false}
      />
    </div>
  )
}
