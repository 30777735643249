/**
 * API response for the core-quadrant feature.
 *
 * @param T - The data type
 * @param E - The error type
 */
import { z } from 'zod'

export type APIResponse<T, E extends Error = Error> =
  | {
      status: 'success'
      data: T
      error?: undefined
    }
  | {
      status: 'failure'
      data?: undefined
      error: E
    }

/**
 * All available steps in the core-quadrant feature.
 */
export const stepsSchema = z.enum([
  'cluster',
  'core-quality',
  'pitfall',
  'allergy',
  'challenge',
  'explore',
  'reflection',
  'result',
  'explore-core-quality',
  'explore-pitfall',
  'explore-allergy',
  'explore-challenge',
  'next-step',
])

export type Steps = z.infer<typeof stepsSchema>

export const steps = stepsSchema.Values
