/**
 * Get absolute url from path
 *
 * @param pathname - Pathname
 */
export const getAbsoluteUrl = (pathname: string) => {
  const { host, protocol } = window.location

  return `${protocol}//${host}${pathname}`
}
