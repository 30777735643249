import { useRouter } from 'next/router'

import { stepsSchema } from '@features/core-quadrant/shared'

/**
 * Get the current page from the search params
 *
 * @returns The current page param or null
 */
export function useStepSearchParam() {
  const router = useRouter()
  const parsed = stepsSchema.safeParse(router.query.step)

  if (parsed.success) {
    return parsed.data
  }

  return null
}
