import type { HTMLAttributes, ReactElement } from 'react'
import { cloneElement, forwardRef, isValidElement, useMemo } from 'react'
import type { AriaButtonProps } from 'react-aria'
import { mergeProps, useButton, useFocusRing, useHover } from 'react-aria'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid'
import { useObjectRef } from '@react-aria/utils'
import type { ClassValue } from 'clsx'
import { clsx } from 'clsx'
import { match } from 'ts-pattern'

import { FocusRing } from '@lederne-ui/focus-ring'

import type { ButtonVariants } from './button.styles'
import { buttonStyles } from './button.styles'

export type ButtonOwnProps = {
  /**
   * Class values to add to the button
   **/
  className?: ClassValue

  /**
   * Add an icon (svg) to the button
   * If passing a custom icon, it must be a HeroIcon from the 20x20 solid package.
   *
   * @see https://heroicons.com/
   */
  icon?: 'arrow' | 'chevron' | ReactElement

  /**
   * The icon position
   */
  iconPosition?: 'start' | 'end'
}

type ButtonProps = AriaButtonProps<'button' | 'a' | 'span'> &
  ButtonOwnProps &
  ButtonVariants

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement,
  ButtonProps
>(
  (
    {
      size,
      variant,
      isDisabled,
      className,
      children,
      autoFocus,
      isActive,
      isInline,
      icon,
      iconPosition = 'end',
      ...rest
    },
    forwardedRef,
  ) => {
    const ref = useObjectRef(forwardedRef)
    const { hoverProps, isHovered } = useHover({ isDisabled })
    const { focusProps, isFocusVisible } = useFocusRing({ autoFocus })
    const { buttonProps, isPressed } = useButton(
      { isDisabled, autoFocus, ...rest },
      ref,
    )

    const buttonClasses = clsx(
      buttonStyles({
        isActive,
        isDisabled,
        isFocusVisible,
        isHovered,
        isInline,
        isPressed,
        size,
        variant,
      }),
      className,
    )

    const buttonState = useMemo(() => {
      if (isDisabled) return 'disabled'
      if (isPressed) return 'pressed'
      if (isHovered) return 'hovered'

      return 'ready'
    }, [isDisabled, isHovered, isPressed])

    const _icon = useMemo(() => {
      const iconProps: Pick<HTMLAttributes<'svg'>, 'className' | 'role'> = {
        role: 'img',
        className: clsx('w-5 h-5', {
          'ml-2': iconPosition === 'end',
          'mr-2': iconPosition === 'start',
          'absolute right-5': icon && variant === 'quality' && isInline,

          /**
           * If the icon is a chevron, we fiddle with the margin in the
           * sides of the button due to visual alignment
           */
          '-ml-2': iconPosition === 'start' && icon === 'chevron',
          '-mr-2': iconPosition === 'end' && icon === 'chevron',
        }),
      }

      const element = match([icon, iconPosition])
        .with(['arrow', 'start'], () => <ArrowLeftIcon />)
        .with(['arrow', 'end'], () => <ArrowRightIcon />)
        .with(['chevron', 'start'], () => <ChevronLeftIcon />)
        .with(['chevron', 'end'], () => <ChevronRightIcon />)
        .otherwise(() => icon)

      return isValidElement(element) ? cloneElement(element, iconProps) : null
    }, [icon, iconPosition, isInline, variant])

    const Component = rest.elementType ?? 'button'

    return (
      <FocusRing autoFocus={autoFocus} focusRingSize={'large'}>
        <Component
          ref={ref}
          {...mergeProps(buttonProps, hoverProps, focusProps)}
          data-state={buttonState}
          data-testid={'button'}
          className={buttonClasses}
        >
          {iconPosition === 'start' ? _icon : null}

          <span>{children}</span>

          {iconPosition === 'end' ? _icon : null}
        </Component>
      </FocusRing>
    )
  },
)

Button.displayName = 'Button'
