import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const containerStyles = cva(['mx-auto px-5 sm:px-6 lg:px-8 xl:px-14'], {
  variants: {
    size: {
      sm: ['max-w-screen-lg'],
      md: ['max-w-screen-xl'],
      lg: ['max-w-screen-2xl'],
      prose: ['max-w-prose'],
      fluid: ['max-w-full'],
    },
  },

  defaultVariants: {
    size: 'lg',
  },
})

export type ContainerVariants = VariantProps<typeof containerStyles>
