import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const dividerStyles = cva(['border-none'], {
  variants: {
    orientation: {
      horizontal: [],
      vertical: [],
    },
    color: {
      yellow: ['bg-sunny-yellow-500'],
      white: ['bg-white'],
      'bloody-orange': ['bg-bloody-orange'],
    },
    size: {
      small: [],
      medium: [],
      large: [],
      xlarge: [],
    },
  },
  compoundVariants: [
    /**
     * Horizontal compound variants
     */
    {
      size: 'small',
      orientation: 'horizontal',
      className: ['h-[2px] w-[11px]'],
    },
    {
      size: 'medium',
      orientation: 'horizontal',
      className: ['h-[4px] w-[22px]'],
    },
    {
      size: 'large',
      orientation: 'horizontal',
      className: ['h-[6px] w-[33px]'],
    },
    {
      size: 'xlarge',
      orientation: 'horizontal',
      className: ['h-[8px] w-[44px]'],
    },

    /**
     * Vertical compound variants
     */
    {
      size: 'small',
      orientation: 'vertical',
      className: ['h-[11px] w-[2px]'],
    },
    {
      size: 'medium',
      orientation: 'vertical',
      className: ['h-[22px] w-[4px]'],
    },
    {
      size: 'large',
      orientation: 'vertical',
      className: ['h-[33px] w-[6px]'],
    },
    {
      size: 'xlarge',
      orientation: 'vertical',
      className: ['h-[44px] w-[8px]'],
    },
  ],
  defaultVariants: {
    size: 'small',
    color: 'yellow',
    orientation: 'horizontal',
  },
})

export type DividerVariants = VariantProps<typeof dividerStyles>
