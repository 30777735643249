import type { ReactNode } from 'react'
import React from 'react'
import type { ClassValue } from 'clsx'
import { clsx } from 'clsx'

import type { ContainerVariants } from '@lederne-ui/container/container.styles'
import { containerStyles } from '@lederne-ui/container/container.styles'

type ContainerOwnProps = {
  /**
   * The content of the container.
   */
  children: ReactNode

  /**
   * Additional classes to apply to the container.
   */
  className?: ClassValue

  /**
   * The element type to render as.
   */
  elementType?: 'div' | 'section'
}

export type ContainerProps = ContainerOwnProps & ContainerVariants

export const Container = ({
  children,
  className,
  elementType: Component = 'div',
  size,
}: ContainerProps) => {
  const containerClasses = clsx(containerStyles({ size }), className)

  return <Component className={containerClasses}>{children}</Component>
}
