import type { ReactNode } from 'react'
import React from 'react'
import clsx from 'clsx'
import { match } from 'ts-pattern'

import { useStepSearchParam } from '@features/core-quadrant/hooks/use-step-search-param'

type LayoutProps = {
  children: ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const step = useStepSearchParam()

  const layoutClasses = match(step)
    .with('explore', () => 'bg-dark-mode-green')
    .with('next-step', () => 'bg-dark-mode-green')
    .with('reflection', () => 'bg-dark-mode-green 2xl:bg-welcoming-green-800')
    .with(
      'explore-core-quality',
      () => 'bg-dark-mode-green 2xl:bg-welcoming-green-800',
    )
    .with(
      'explore-pitfall',
      () => 'bg-dark-mode-green 2xl:bg-welcoming-green-800',
    )
    .with(
      'explore-challenge',
      () => 'bg-dark-mode-green 2xl:bg-welcoming-green-800',
    )
    .with(
      'explore-allergy',
      () => 'bg-dark-mode-green 2xl:bg-welcoming-green-800',
    )

    .otherwise(() => false)

  return (
    <div
      className={clsx(
        'flex min-h-screen flex-col transition-colors duration-1000',
        layoutClasses,
      )}
    >
      <main className={'flex-1'}>{children}</main>
    </div>
  )
}
