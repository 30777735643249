import { getReferrer } from '@utils/get-referrer'

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[]
}

declare const window: WindowWithDataLayer

type TrackEventParams = {
  eventCategory: 'kernekvadranten'
  eventAction: string
  eventLabel?: string
} & Record<string, unknown>

const trackEvent = (args: TrackEventParams) => {
  return window.dataLayer.push({ event: 'gaEvent', ...args })
}

type TrackVirtualPageViewParams = {
  pageUrl: string
  pagePath: string
} & Record<string, unknown>

const trackVirtualPageView = (args: TrackVirtualPageViewParams) => {
  return window.dataLayer.push({
    event: 'virtualPageView',
    referrer: getReferrer(),
    ...args,
  })
}

export const gtm = {
  trackEvent,
  trackVirtualPageView,
}
