import type { ReactElement } from 'react'
import React from 'react'
import type { FocusRingProps as AriaFocusRingProps } from '@react-aria/focus'
import { FocusRing as AriaFocusRing } from '@react-aria/focus'
import { clsx } from 'clsx'

type FocusRingOwnProps = {
  /**
   * Element to draw the FocusRing around
   */
  children: ReactElement

  /**
   * Size of the focus ring
   */
  focusRingSize?: 'small' | 'large'
}

export type FocusRingProps = AriaFocusRingProps & FocusRingOwnProps

export const FocusRing = ({
  children,
  focusRingSize = 'small',
  ...props
}: FocusRingProps) => (
  <AriaFocusRing
    {...props}
    focusRingClass={clsx(
      'ring-offset-transparent ring-focus-ring transition duration-200 ease-in-out',
      {
        'ring-1 ring-offset-1': focusRingSize === 'small',
        'ring-2 ring-offset-1': focusRingSize === 'large',
      },
    )}
  >
    {children}
  </AriaFocusRing>
)
