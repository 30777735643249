import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const buttonStyles = cva(
  [
    'border',
    'border-2',
    'duration-200',
    'ease-in-out',
    'font-semibold',
    'inline-flex',
    'items-center',
    'justify-center',
    'outline-none',
    'relative',
    'transition',
    'whitespace-nowrap',
    'tracking-wide',
  ],
  {
    variants: {
      variant: {
        yellow: ['border-transparent', 'text-primary', 'uppercase', 'rounded'],
        white: ['border-transparent', 'text-primary', 'uppercase', 'rounded'],
        green: ['border-transparent', 'text-white', 'uppercase', 'rounded'],
        'minty-green': [
          'border-transparent',
          'text-dark-mode-green',
          'uppercase',
          'rounded',
        ],
        quality: ['border-minty-green-300', 'rounded-full'],
        'yellow-border': ['uppercase', 'rounded'],
        'text-white': [
          'border-transparent',
          'text-white',
          'uppercase',
          'rounded',
        ],
        'text-black': [
          'border-transparent',
          'text-black',
          'uppercase',
          'rounded',
        ],
      },
      size: {
        small: ['text-sm', 'h-[40px]', 'px-4'],
        medium: ['text-sm', 'h-[46px]', 'px-6'],
        large: ['text-base', 'h-[50px]', 'px-8'],
        xlarge: ['text-base', 'h-[60px]', 'px-12'],
      },
      isInline: {
        true: ['inline-flex', 'w-full'],
      },
      isPressed: {
        true: [],
      },
      isHovered: {
        true: [],
      },
      isFocusVisible: {
        true: [],
      },
      isActive: {
        true: [],
      },
      isDisabled: {
        true: ['opacity-50'],
      },
    },
    compoundVariants: [
      /**
       * Primary
       */
      {
        variant: 'yellow',
        isPressed: false,
        isHovered: false,
        className: ['bg-sunny-yellow-500'],
      },
      {
        variant: 'yellow',
        isHovered: true,
        isPressed: false,
        className: ['bg-sunny-yellow-600'],
      },
      {
        variant: 'yellow',
        isPressed: true,
        className: ['bg-sunny-yellow-700'],
      },

      /**
       * White
       */
      {
        variant: 'white',
        isPressed: false,
        isHovered: false,
        className: ['bg-white'],
      },
      {
        variant: 'white',
        isHovered: true,
        isPressed: false,
        className: ['bg-sand-200'],
      },
      {
        variant: 'white',
        isPressed: true,
        className: ['bg-sand-300'],
      },

      /**
       * Green
       */
      {
        variant: 'green',
        isPressed: false,
        isHovered: false,
        className: ['bg-welcoming-green-800'],
      },
      {
        variant: 'green',
        isHovered: true,
        isPressed: false,
        className: ['bg-welcoming-green-700'],
      },
      {
        variant: 'green',
        isPressed: true,
        className: ['bg-welcoming-green-800'],
      },

      /**
       * Minty Green
       */
      {
        variant: 'minty-green',
        isPressed: false,
        isHovered: false,
        className: ['bg-minty-green-500'],
      },
      {
        variant: 'minty-green',
        isHovered: true,
        isPressed: false,
        className: ['bg-minty-green-600'],
      },
      {
        variant: 'minty-green',
        isPressed: true,
        className: ['bg-minty-green-700'],
      },

      /**
       * Text white
       */
      {
        variant: 'text-white',
        isPressed: false,
        isHovered: false,
        className: ['bg-transparent'],
      },
      {
        variant: 'text-white',
        isHovered: true,
        isPressed: false,
        className: ['bg-transparent', 'bg-white/10'],
      },
      {
        variant: 'text-white',
        isPressed: true,
        className: ['bg-transparent', 'bg-white/20'],
      },

      /**
       * Text black
       */
      {
        variant: 'text-black',
        isPressed: false,
        isHovered: false,
        className: ['bg-transparent'],
      },
      {
        variant: 'text-black',
        isHovered: true,
        isPressed: false,
        className: ['bg-transparent', 'bg-black/10'],
      },
      {
        variant: 'text-black',
        isPressed: true,
        className: ['bg-transparent', 'bg-black/20'],
      },

      /**
       * Yellow border
       */
      {
        variant: 'yellow-border',
        isPressed: false,
        isHovered: false,
        className: [
          'border-sunny-yellow-500',
          'bg-transparent',
          'text-sunny-yellow-500',
        ],
      },
      {
        variant: 'yellow-border',
        isHovered: true,
        isPressed: false,
        className: [
          'border-sunny-yellow-500',
          'bg-sunny-yellow-500',
          'text-dark-mode-green',
        ],
      },
      {
        variant: 'yellow-border',
        isPressed: true,
        className: [
          'border-sunny-yellow-700',
          'bg-sunny-yellow-700',
          'text-dark-mode-green',
        ],
      },

      /**
       * Quality
       */
      {
        variant: 'quality',
        isPressed: false,
        isHovered: false,
        isActive: false,
        className: ['bg-transparent', 'text-minty-green-300'],
      },
      {
        variant: 'quality',
        isHovered: true,
        isPressed: false,
        className: ['bg-minty-green-300', 'text-primary'],
      },
      {
        variant: 'quality',
        isPressed: true,
        className: [
          'bg-minty-green-500',
          'border-minty-green-500',
          'text-primary',
        ],
      },
      {
        variant: 'quality',
        isActive: true,
        isPressed: false,
        isHovered: false,
        className: ['bg-minty-green-300', 'text-primary'],
      },
    ],
    defaultVariants: {
      variant: 'yellow',
      size: 'medium',
    },
  },
)

export type ButtonVariants = VariantProps<typeof buttonStyles>
