import React from 'react'
import type { SeparatorProps as AriaDividerProps } from 'react-aria'
import { useSeparator } from 'react-aria'
import type { ClassValue } from 'clsx'
import { clsx } from 'clsx'

import type { DividerVariants } from '@lederne-ui/divider/divider.styles'
import { dividerStyles } from '@lederne-ui/divider/divider.styles'

type DividerOwnProps = {
  className?: ClassValue
}

type DividerProps = DividerOwnProps & AriaDividerProps & DividerVariants

export const Divider = ({
  className,
  color,
  orientation,
  size,
  ...props
}: DividerProps) => {
  const { separatorProps } = useSeparator(props)

  const dividerClasses = clsx(
    dividerStyles({ size, color, orientation }),
    className,
  )

  const ElementType = orientation === 'horizontal' ? 'hr' : 'div'

  return <ElementType className={dividerClasses} {...separatorProps} />
}
